/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.welcome {
  background-image: url("../../images/background_2023.jpg");
  background-repeat: no-repeat;
  min-height: 52vw;
  background-position: 0 5%;
  overflow: hidden;
  position: relative; }
  @media only screen and (min-width: 1800px) {
    .welcome {
      background-size: 100vw; } }
  @media only screen and (max-width: 1280px) {
    .welcome {
      background-position: 40% 5%;
      background-size: 140vw; } }
  @media only screen and (max-width: 768px) {
    .welcome {
      background-position: 40% 5%;
      background-size: cover;
      min-height: 440px; } }
  .welcome .welcome__titlepic_off {
    display: none; }
  .welcome .welcome__titlepic {
    z-index: 5;
    position: relative;
    text-align: center;
    max-width: 550px;
    margin: 0 auto;
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 50px; }
    @media only screen and (min-width: 1800px) {
      .welcome .welcome__titlepic {
        padding-top: 12vw;
        max-width: 500px; } }
    @media only screen and (max-width: 1800px) {
      .welcome .welcome__titlepic {
        max-width: 425px;
        padding-top: 12vw; } }
    @media only screen and (max-width: 1280px) {
      .welcome .welcome__titlepic {
        padding-top: 150px; } }
    @media only screen and (max-width: 1024px) {
      .welcome .welcome__titlepic {
        padding-top: 120px; } }
    @media only screen and (max-width: 768px) {
      .welcome .welcome__titlepic {
        padding: 120px 80px 0 80px; } }
    .welcome .welcome__titlepic img {
      width: 100%; }
  .welcome .welcome__stopPlayer {
    z-index: 10;
    position: relative;
    float: right;
    margin-right: 12vw;
    margin-top: 20px; }
    @media only screen and (max-width: 360px) {
      .welcome .welcome__stopPlayer {
        display: none; } }
    .welcome .welcome__stopPlayer button {
      border: none;
      outline: none;
      background: transparent;
      cursor: pointer; }
      .welcome .welcome__stopPlayer button i,
      .welcome .welcome__stopPlayer button p {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black; }
      .welcome .welcome__stopPlayer button i {
        font-size: 40px; }
      .welcome .welcome__stopPlayer button p {
        font-weight: 600;
        margin: 10px 0 0 0; }
  .welcome .welcome__button {
    background-color: #56295b;
    font-weight: 400;
    font-size: 20px;
    color: white;
    border: 2px dashed white;
    outline: none;
    cursor: pointer;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    bottom: 25%;
    left: 46%;
    transition: background-color 1s; }
    @media only screen and (max-width: 1024px) {
      .welcome .welcome__button {
        bottom: 40%; } }
    @media only screen and (max-width: 768px) {
      .welcome .welcome__button {
        font-size: 18px;
        border: 1px dashed white;
        left: 40%; } }
    .welcome .welcome__button:hover {
      background-color: #E54B4B; }

.welcome__button--scrolled {
  display: none; }

.welcome__video {
  position: absolute;
  overflow: hidden;
  width: 100vw;
  height: 57vw;
  top: -60px;
  z-index: 1; }
  @media only screen and (max-width: 560px) {
    .welcome__video {
      width: 300vw !important;
      height: 440px !important;
      left: -100vw !important;
      top: 0vw; } }
  @media only screen and (max-width: 768px) {
    .welcome__video {
      width: 180vw;
      height: 480px;
      left: -40vw; } }

.welcome__videoScreen,
.welcome__videoCover {
  z-index: 0;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 52vw; }
  @media only screen and (max-width: 768px) {
    .welcome__videoScreen,
    .welcome__videoCover {
      height: 540px; } }
  @media only screen and (max-width: 360px) {
    .welcome__videoScreen,
    .welcome__videoCover {
      display: none; } }

.welcome__videoCover {
  z-index: 2; }
