/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.notFound404 {
  text-align: center;
  background-color: #f2eae8;
  padding: 200px 0; }
  .notFound404 h1, .notFound404 h3 {
    margin: 0; }
