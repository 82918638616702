/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.footer {
  background-color: #1E1E24;
  padding: 20px 0 80px 0;
  min-height: 100px; }
  .footer h1,
  .footer h2,
  .footer h3,
  .footer h4,
  .footer h5,
  .footer p,
  .footer span {
    color: white; }

.footer__socialIcons {
  text-align: center; }
  @media only screen and (max-width: 768px) {
    .footer__socialIcons {
      margin-bottom: 20px; } }
  .footer__socialIcons a {
    width: 36px !important;
    height: 36px !important;
    margin-left: 6px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle; }
    .footer__socialIcons a div.social-container {
      width: 30px !important;
      height: 30px !important;
      margin-left: 3px;
      margin-top: 3px; }
      .footer__socialIcons a div.social-container:hover {
        width: 100% !important;
        height: 100% !important;
        margin: 0; }
  .footer__socialIcons img {
    width: 30px;
    margin-top: 3px;
    margin-left: 3px;
    border-radius: 50%; }
    .footer__socialIcons img:hover {
      width: 36px;
      margin: 0; }
  .footer__socialIcons :first-child {
    margin-left: 0px; }
  .footer__socialIcons :last-child[aria-label=github] g.social-svg-mask {
    fill: lightgrey !important; }

.footer__copyright {
  margin-top: 40px;
  text-align: center; }
