/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.blog__post {
  max-width: 800px;
  margin: 0 auto; }
  .blog__post * {
    max-width: 100%; }
  .blog__post img {
    display: block;
    margin: 0 auto; }
  .blog__post .wp-block-image {
    margin: 0; }
  .blog__post .blog__post__body {
    background-color: white;
    border-radius: 20px;
    padding: 10px 30px;
    margin-bottom: 20px; }
  .blog__post .blog__post__time {
    width: 90px;
    background-color: #56295b;
    color: white;
    padding: 5px 30px;
    margin: 0;
    margin-left: 20px;
    clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%); }
  .blog__post .blog__post__title {
    max-width: calc(100% - 50px);
    display: inline-block;
    margin: 10px 0; }
    .blog__post .blog__post__title:hover {
      color: #E54B4B; }
  .blog__post i {
    font-size: 30px;
    margin-top: 10px;
    float: right;
    padding-left: 20px;
    color: #56295b; }
    .blog__post i:hover {
      color: #E54B4B; }

.blog__post__content {
  display: none;
  padding: 0 5px; }

.expandedPost {
  display: block; }
