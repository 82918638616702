/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }
  body h1,
  body h2,
  body h3,
  body h4,
  body h5 {
    color: #56295b; }
  body p,
  body li,
  body em,
  body i,
  body b {
    color: #333333; }
  body .special_text {
    font-weight: 200; }
  body h1 {
    font-size: 42px;
    font-weight: 300; }
    @media only screen and (max-width: 520px) {
      body h1 {
        font-size: 30px; } }
  body h2 {
    font-size: 30px;
    font-weight: 300; }
    @media only screen and (max-width: 520px) {
      body h2 {
        font-size: 24px; } }
  body h3 {
    font-size: 26px;
    font-weight: 300; }
    @media only screen and (max-width: 520px) {
      body h3 {
        font-size: 20px; } }
  body h4 {
    font-size: 22px;
    font-weight: 300; }
    @media only screen and (max-width: 520px) {
      body h4 {
        font-size: 18px; } }
  body h5 {
    font-size: 18px;
    font-weight: 300; }
    @media only screen and (max-width: 520px) {
      body h5 {
        font-size: 16px; } }
  body p {
    font-size: 17px; }
    @media only screen and (max-width: 520px) {
      body p {
        font-size: 14px; } }
  body a {
    color: #56295b; }
    body a:visited {
      color: #56295b; }
    body a:active {
      color: #56295b; }

.content {
  padding: 0 12%;
  max-width: 1280px;
  margin: 0 auto; }

@media only screen and (max-width: 768px) {
  .content {
    padding: 0 40px; } }

@media only screen and (max-width: 1024px) {
  .content {
    padding: 0 6%; } }

.clickable {
  cursor: pointer; }
