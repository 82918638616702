/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.blog {
  background-color: #f2eae8;
  padding: 50px 20px;
  outline: 1px solid #56295b; }
  .blog .loader {
    text-align: center; }
  .blog img {
    height: auto; }
  .blog figcaption {
    padding-top: 20px; }
  .blog .blog__underConstruction {
    text-align: center; }
