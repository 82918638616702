/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.navigation {
  z-index: 3;
  display: inline-block;
  vertical-align: bottom; }
  .navigation ul {
    margin: 0;
    padding: 0; }
    @media only screen and (min-width: 1280px) {
      .navigation ul {
        margin-left: 40px; } }
    .navigation ul a:last-child li {
      padding-right: 50px;
      border-right: 0;
      -webkit-clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%); }
      @media only screen and (max-width: 768px) {
        .navigation ul a:last-child li {
          padding-right: 0;
          -webkit-clip-path: none;
          clip-path: none; } }
    .navigation ul a:first-child li {
      padding-left: 50px;
      -webkit-clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%); }
      @media only screen and (max-width: 768px) {
        .navigation ul a:first-child li {
          padding-left: 0;
          -webkit-clip-path: none;
          clip-path: none; } }
  @media only screen and (max-width: 768px) {
    .navigation {
      display: block; }
      .navigation ul {
        display: flex;
        margin-left: 0; }
        .navigation ul a {
          flex: auto; }
          .navigation ul a:last-child li {
            width: 100%; }
          .navigation ul a .navigation__navItem {
            padding: 7px 0;
            width: calc(100% - 1px);
            text-align: center;
            font-size: 15px; } }
  .navigation .navigation__navItem {
    background-color: #56295b;
    border-right: 1px solid white;
    display: inline-block;
    padding: 7px 30px;
    color: white;
    font-size: 16px;
    transition: background-color 0.5s; }
    @media only screen and (max-width: 1280px) and (min-width: 1024px) {
      .navigation .navigation__navItem {
        font-size: 14px; } }
    @media only screen and (max-width: 1024px) {
      .navigation .navigation__navItem {
        font-size: 13px; } }
    .navigation .navigation__navItem:hover {
      background-color: #E54B4B; }
  .navigation .navigation__navItem--active {
    background-color: #b375ba; }
  .navigation.navigation--scrolled {
    width: 260px; }
    .navigation.navigation--scrolled .navigation__navItem {
      padding: 5px 0;
      width: calc(100% - 1px);
      text-align: center;
      font-size: 15px; }
    .navigation.navigation--scrolled ul {
      display: flex;
      margin-left: 0; }
      .navigation.navigation--scrolled ul a {
        flex: auto; }
        .navigation.navigation--scrolled ul a:last-child li {
          -webkit-clip-path: none;
          clip-path: none;
          width: 100%; }
        .navigation.navigation--scrolled ul a:first-child li {
          -webkit-clip-path: none;
          clip-path: none; }
