/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.header {
  height: 141px; }
  @media only screen and (max-width: 768px) {
    .header {
      height: 130px; } }

@media only screen and (max-width: 768px) {
  .header__picNav {
    padding: 0;
    left: 0 !important;
    position: fixed;
    padding-top: 20px;
    padding-left: calc(50% - 125px) !important; } }

.header__socialIcons {
  display: inline-block;
  padding: 0 0 5px 0;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center; }
  @media only screen and (max-width: 1024px) {
    .header__socialIcons {
      display: none !important; } }
  .header__socialIcons a {
    width: 36px !important;
    height: 36px !important;
    margin-left: 6px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle; }
    .header__socialIcons a div.social-container {
      width: 30px !important;
      height: 30px !important;
      margin-left: 3px;
      margin-top: 3px; }
      .header__socialIcons a div.social-container:hover {
        width: 100% !important;
        height: 100% !important;
        margin: 0; }
  .header__socialIcons img {
    width: 30px;
    margin-top: 3px;
    margin-left: 3px;
    border-radius: 50%; }
    .header__socialIcons img:hover {
      width: 36px;
      margin: 0; }
  .header__socialIcons :first-child {
    margin-left: 0px; }

.header__picNav--scrolled {
  padding: 0;
  position: fixed;
  z-index: 20; }
  .header__picNav--scrolled .header__socialIcons {
    display: block; }
  .header__picNav--scrolled .header__title {
    margin-left: 0; }

.header__looking-for-engineering {
  float: right;
  padding-right: 100px;
  padding-top: 30px;
  text-align: right; }
  @media only screen and (max-width: 1200px) {
    .header__looking-for-engineering {
      display: none; } }
