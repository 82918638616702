/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.engineering {
  padding-top: 50px; }
  .engineering .eng__namecard {
    border-radius: 20px;
    border: 2px dashed gray;
    max-width: 600px;
    padding: 5px 20px;
    margin: 0 auto; }
    @media only screen and (max-width: 768px) {
      .engineering .eng__namecard {
        text-align: center; } }
    .engineering .eng__namecard .eng__namecard__text {
      max-width: 400px;
      display: inline-block;
      margin-right: 50px; }
      @media only screen and (max-width: 768px) {
        .engineering .eng__namecard .eng__namecard__text {
          margin-right: 0; } }
    .engineering .eng__namecard .eng__namecard__image {
      display: inline-block;
      vertical-align: top; }
      .engineering .eng__namecard .eng__namecard__image img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 50%;
        border: 2px dashed gray; }
  .engineering .eng__looking-for-link {
    text-align: right;
    margin: 0;
    margin-top: -3px;
    padding-right: 100px; }
  .engineering .eng__socialIcons {
    display: inline-block;
    padding: 0 0 5px 0;
    margin-right: 5px;
    margin-left: -5px;
    margin-bottom: -12px;
    background-color: rgba(255, 255, 255, 0.6); }
    .engineering .eng__socialIcons a {
      width: 36px !important;
      height: 36px !important;
      display: inline-block;
      position: relative;
      overflow: hidden;
      vertical-align: middle; }
      .engineering .eng__socialIcons a div.social-container {
        width: 30px !important;
        height: 30px !important;
        margin-left: 3px;
        margin-top: 3px; }
        .engineering .eng__socialIcons a div.social-container:hover {
          width: 100% !important;
          height: 100% !important;
          margin: 0; }
    .engineering .eng__socialIcons img {
      width: 30px;
      margin-top: 3px;
      margin-left: 3px;
      border-radius: 50%; }
      .engineering .eng__socialIcons img:hover {
        width: 36px;
        margin: 0; }
  .engineering .eng__Resume {
    display: inline-block;
    margin-left: 20px; }
  .engineering .eng__projects h4,
  .engineering .eng__projects h5 {
    margin: 0; }
