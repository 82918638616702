/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.toTop {
  background-color: #56295b;
  width: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 20px;
  opacity: .5;
  transition: opacity 500ms, background-color 500ms; }
  .toTop p, .toTop i {
    color: white;
    margin: 0;
    margin-bottom: 10px; }
  .toTop i {
    font-size: 30px;
    margin-top: 5px; }
  .toTop:hover {
    opacity: 1;
    background-color: #E54B4B; }
  .toTop.toTop--hidden {
    display: none; }
