/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.projectList {
  overflow: scroll;
  padding: 20px 0;
  position: relative;
  height: 454px; }
  @media only screen and (max-width: 1024px) {
    .projectList {
      height: 400px; } }
  .projectList::-webkit-scrollbar {
    display: none; }
  .projectList .projectList__reel {
    white-space: nowrap;
    position: absolute; }
    .projectList .projectList__reel .projectList__reel__connector {
      position: absolute;
      z-index: -5;
      top: 50%;
      border-top: 2px dashed #aaa;
      width: calc(100% - 80px);
      left: 40px; }

.projectListWrapper {
  position: relative; }
  .projectListWrapper .projectList__back, .projectListWrapper .projectList__forward {
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: calc(50% - 36px);
    opacity: .5;
    transition: opacity 500ms; }
    .projectListWrapper .projectList__back i, .projectListWrapper .projectList__forward i {
      font-size: 80px;
      color: #56295b;
      transition: color 500ms; }
    .projectListWrapper .projectList__back:hover, .projectListWrapper .projectList__forward:hover {
      opacity: 1; }
      .projectListWrapper .projectList__back:hover i, .projectListWrapper .projectList__forward:hover i {
        color: #E54B4B; }
    .projectListWrapper .projectList__back.projectList__hidden, .projectListWrapper .projectList__forward.projectList__hidden {
      display: none; }
  .projectListWrapper .projectList__back {
    left: -60px; }
    @media only screen and (max-width: 1280px) {
      .projectListWrapper .projectList__back {
        left: -40px; } }
    @media only screen and (max-width: 768px) {
      .projectListWrapper .projectList__back {
        left: 0px; } }
  .projectListWrapper .projectList__forward {
    right: -60px; }
    @media only screen and (max-width: 1280px) {
      .projectListWrapper .projectList__forward {
        right: -40px; } }
    @media only screen and (max-width: 768px) {
      .projectListWrapper .projectList__forward {
        right: 0px; } }
