/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.header__image {
  display: inline-block;
  vertical-align: middle; }
  .header__image img {
    object-fit: cover;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px dashed #56295b;
    transition: width 500ms, height 500ms; }
    @media only screen and (max-width: 768px) {
      .header__image img {
        border: 2px dashed #56295b;
        margin-top: 2px; } }

.header__title {
  max-width: 300px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px; }
  .header__title h4 {
    margin: 10px 0 0 0;
    transition: margin 500ms; }
    .header__title h4.small_text {
      font-size: 13px; }

.header__titleImg {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 15.5px 30px;
  display: inline-block;
  top: 0; }
  @media only screen and (max-width: 768px) {
    .header__titleImg {
      padding: 5px 35px;
      transition: padding 500ms, margin-top 500ms; }
      .header__titleImg .header__image img {
        width: 60px;
        height: 60px;
        transition: width 500ms, height 500ms; }
      .header__titleImg h4 {
        margin: 0 !important;
        transition: margin 500ms;
        font-size: 16px; }
      .header__titleImg h2 {
        font-size: 22px; } }

.header__titleImg--scrolled {
  width: 100%;
  max-width: 200px;
  padding: 5px 30px;
  transition: padding 500ms, margin-top 500ms; }
  .header__titleImg--scrolled .header__image img {
    display: none; }
  .header__titleImg--scrolled h4 {
    display: inline-block;
    margin: 0 0 0 15px !important;
    transition: margin 500ms;
    font-size: 18px; }
  .header__titleImg--scrolled h2 {
    display: inline-block;
    font-size: 22px; }
