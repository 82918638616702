/* Color Pallette */
/* Font colors */
/* Breakpoints */
/* Indents */
.portfolio .portfolio__bannerImg {
  text-align: center; }
  .portfolio .portfolio__bannerImg img {
    width: 70%; }
    @media only screen and (max-width: 768px) {
      .portfolio .portfolio__bannerImg img {
        width: 100%; } }

.portfolio hr {
  color: #ccc; }

.portfolio .portfolio__overview__blockquote {
  border: 2px dashed #56295b;
  border-radius: 20px;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px; }

.portfolio .portfolio__subnav {
  text-align: center; }
  .portfolio .portfolio__subnav :first-child {
    margin-left: 0; }

.portfolio a {
  font-size: 20px;
  cursor: pointer;
  margin-left: 20px;
  text-decoration: underline; }
  @media only screen and (max-width: 520px) {
    .portfolio a {
      display: block;
      margin: 0; } }
  .portfolio a:hover {
    color: #E54B4B; }
